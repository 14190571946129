import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import {
  deleteMailById,
  getMailById,
  updateMailStatus,
} from "../../redux/Inbox/Inbox";
import {
  getEmailTime,
  getFromEmail,
  getName,
  headerDate,
} from "../../utils/InboxFunctions";
import parse from "html-react-parser";

import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { ReactComponent as Reply } from "../../assets/icons/reply.svg";
import { ReactComponent as Archive } from "../../assets/icons/archive-email.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash-email.svg";
import { ReactComponent as More } from "../../assets/icons/more.svg";
import { ReactComponent as Avatar } from "../../assets/icons/avatar.svg";
import { ReactComponent as Forward } from "../../assets/icons/forward.svg";

import "./EmailDetail.scss";
import { Letter } from "react-letter";
import { Popconfirm } from "antd";

const EmailDetail = () => {
  const [mail, setMail] = useState(null);

  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id)
      dispatch(getMailById(id)).then(({ payload }) => {
        setMail(payload);
        if (!payload?.is_read) dispatch(updateMailStatus(payload.message_id));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="emailDetail">
      <header>
        <Back onClick={() => navigate(-1)} title="Back" />
        <section>
          <Archive title="Archive" />
          <Popconfirm
            title="Delete the mail"
            description="Are you sure to delete this mail?"
            onConfirm={() => {
              dispatch(deleteMailById(id)).then(() => navigate(-1));
            }}
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
          >
            <Trash title="Delete" />
          </Popconfirm>
          <Reply title="Reply" />
          <More />
        </section>
      </header>
      {mail && (
        <section className="emailDetail__container">
          <div className="subject">{mail?.subject}</div>
          <div className="sender">
            {/* <Avatar /> */}
            <div className="avatar">
              {getName(mail.from).slice(0, 1) === "'" ||
              getName(mail.from).slice(0, 1) === '"'
                ? getName(mail.from).slice(1, 2)
                : getName(mail.from).slice(0, 1)}
            </div>
            <section>
              <div>
                {/* {getName(mail.from)} <span>{getEmailTime(mail.sent)}</span> */}
                {mail.from}{" "}
                <span>{getEmailTime(mail?.sent ?? mail?.received_at)}</span>
              </div>
              <p>To: {mail.to}</p>
            </section>
          </div>
          {mail.body_html.includes("<!DOCTYPE html>") ? (
            <Letter preserveCssPriority={false} html={mail.body_html} />
          ) : (
            <div className="email">{parse(mail.body_html)}</div>
          )}
        </section>
      )}
      <div className="emailDetail__button">
        <button>
          <Reply /> Reply
        </button>
        <button>
          <Forward /> Forward
        </button>
      </div>
    </div>
  );
};

export default EmailDetail;
