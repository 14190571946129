import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getJWTToken } from "../../utils/GetJWTToken";

const initialState = {
  awaysList: [],
};

export const getAwaysList = createAsyncThunk("getAwaysList", async () => {
  try {
    const token = getJWTToken()
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v3/aways`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(res.data);

    return res.data;
  } catch (err) {
    console.log(err);
  }
});

export const disableAway = createAsyncThunk("disableAway", async (data) => {
  try {
    const token = getJWTToken()
    const res = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/v3/away/${data.id}`,
      {
        is_enabled: data.enable,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
});

export const createAway = createAsyncThunk("createAway", async (data) => {
  try {
    const token = getJWTToken()
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v3/away`,
      {
        title: data.title,
        repeat: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        activate_allow: new Date().toISOString(),
        deactivate_allow: data.deactivateDate,
        is_enabled: true,
        all_day: true,
        message: data.message,
        allowed_contacts: [],
        allowed_keywords: data.keywords,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
});

export const AwaysSlice = createSlice({
  name: "Away",
  initialState,
  reducers: {},

  extraReducers: {
    [getAwaysList.pending]: (state) => {},
    [getAwaysList.fulfilled]: (state, action) => {
      if (action.payload === null) {
        state.awaysList = [];
      } else {
        state.awaysList = action.payload;
      }
    },
    [getAwaysList.rejected]: (state, { payload }) => {},
  
    [disableAway.pending]: (state) => {},
    [disableAway.fulfilled]: (state, action) => {},
    [disableAway.rejected]: (state, { payload }) => {},
    
    [createAway.pending]: (state) => {},
    [createAway.fulfilled]: (state, action) => {},
    [createAway.rejected]: (state, { payload }) => {},
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = AwaysSlice.actions;

export default AwaysSlice.reducer;
