import React, { useEffect } from "react";

import { Switch } from "@mui/material";

import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Away } from "../../assets/icons/away.svg";

import Avatar from "../../assets/images/avatar-2.svg";
import { useDispatch, useSelector } from "react-redux";
import { disableAway, getAwaysList } from "../../redux/Aways/Aways";

const FrequentContacts = () => {
  const dispatch = useDispatch();

  const handleUpdate = (id, enable) => {
    dispatch(
      disableAway({
        id,
        enable,
      })
    ).then(() => dispatch(getAwaysList()));
  };

  const awaysList = useSelector((state) => state.aways.awaysList.data);

  useEffect(() => {
    dispatch(getAwaysList()).then((data) => console.log(data));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="feed__contacted-container">
      <div className="feed__contacted-title">Frequently contacted</div>
      <section className="feed__frequent-contacted">
        <div>
          <img src={Avatar} alt="name" />
          Allison Meadows
          <Edit />
        </div>
        <div>
          <img src={Avatar} alt="name" />
          Allison Meadows
          <Edit />
        </div>
        <div>
          <img src={Avatar} alt="name" />
          Allison Meadows
          <Edit />
        </div>
        <div>
          <img src={Avatar} alt="name" />
          Allison Meadows
          <Edit />
        </div>
      </section>
      <div className="feed__contacted-title">Aways</div>
      <section className="feed__frequent-contacted">
        {awaysList?.length > 0 &&
          awaysList.map((away) => (
            <main>
              <Away />
              {away.title.slice(0, 18)} {away.title.length > 18 ? "..." : ""}
              <Switch
                defaultChecked={away.is_enabled}
                value={away.is_enabled}
                onChange={(e) => {
                  handleUpdate(away.id, !away.is_enabled);
                }}
              />
            </main>
          ))}
      </section>
    </div>
  );
};

export default FrequentContacts;
