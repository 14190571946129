import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo-blue.svg";
import { ReactComponent as Cog } from "../../assets/icons/settings.svg";
import { ReactComponent as DropDown } from "../../assets/icons/dropdown.svg";

import "./Navbar.scss";
import Searchbar from "../Searchbar/Searchbar";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../utils/JWTDecoder";

const Navbar = () => {
  const [settings, setSettings] = useState(false);

  const ref = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (e.target === ref.current) {
        console.log(ref.current);
        setSettings(true);
      } else {
        setSettings(false);
      }
    });
  });

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <nav className="navbar">
      <div className="navbar__hamburger">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Logo />
      <Searchbar />
      <div className="navbar__settings">
        <div className="navbar__settings-col-1">
          <Cog
            onClick={() => setSettings(!settings)}
            onBlurCapture={() => setSettings(false)}
            ref={ref}
          />
          <img src={getProfile().photo} alt="profile" />
          {settings && (
            <div className="menu">
              <div onClick={() => navigate("/profile")}>Profile</div>
              <div onClick={handleLogout}>Logout</div>
            </div>
          )}
        </div>
        <section></section>
        <div className="navbar__settings-col-2">
          <span></span>
          Available
          <DropDown />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
