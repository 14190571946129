import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getJWTToken } from "../../utils/GetJWTToken";

const initialState = {
  inbox: [],
  mailCounts: {},
};

export const getUserInbox = createAsyncThunk("getUserInbox", async (data) => {
  try {
    let url;
    if (data.val === "#inbox") {
      url = `/v3/user/message/inbox?nextPageToken=${data?.next ?? ""}`;
    } else if (data.val === "#sent") {
      url = "/v3/user/message/sent";
    } else if (data.val === "#trash") {
      url = "/v3/user/message/trash";
    } else if (data.val === "#spam") {
      url = "/v3/user/message/spam";
    }
    const token = getJWTToken();

    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data);
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
});

export const getMailCounts = createAsyncThunk("getMailCounts", async () => {
  try {
    let obj = {};

    const token = getJWTToken();
    const inboxCount = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v3/label/inbox`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(inboxCount.data.data);
    obj = inboxCount.data.data;

    return obj;
  } catch (err) {
    console.log(err);
  }
});

export const getMailById = createAsyncThunk("getMailById", async (id) => {
  try {
    const token = getJWTToken();
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v3/user/detail/message/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
});

export const sendMail = createAsyncThunk("sendMail", async (data) => {
  console.log(data);
  try {
    const token = getJWTToken();
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v3/send`,
      {
        to: data.to,
        message: data.message,
        subject: data.subject,
        cc: "",
        bcc: "",
        attachments_url: [],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
});

export const getFileUrl = createAsyncThunk("getFileUrl", async (data) => {
  console.log(data);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v2/file`,
      data
    );

    return res.data.data.url;
  } catch (err) {
    console.log(err);
  }
});

export const deleteMailById = createAsyncThunk("deleteMailById", async (id) => {
  try {
    const token = getJWTToken();
    const res = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/v3/message/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
});

export const updateMailStatus = createAsyncThunk(
  "updateMailStatus",
  async (id) => {
    try {
      const token = getJWTToken();
      const res = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/v3/message/${id}`,
        {
          is_read: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const InboxSlice = createSlice({
  name: "Inbox",
  initialState,
  reducers: {},

  extraReducers: {
    [getUserInbox.pending]: (state) => {},
    [getUserInbox.fulfilled]: (state, action) => {
      state.inbox = action.payload.messages;
    },
    [getUserInbox.rejected]: (state, { payload }) => {},

    [getMailCounts.pending]: (state) => {},
    [getMailCounts.fulfilled]: (state, action) => {
      state.mailCounts = action.payload;
    },
    [getMailCounts.rejected]: (state, { payload }) => {},

    [sendMail.pending]: (state) => {},
    [sendMail.fulfilled]: (state, action) => {},
    [sendMail.rejected]: (state, { payload }) => {},

    [getFileUrl.pending]: (state) => {},
    [getFileUrl.fulfilled]: (state, action) => {},
    [getFileUrl.rejected]: (state, { payload }) => {},

    [getMailById.pending]: (state) => {},
    [getMailById.fulfilled]: (state, action) => {},
    [getMailById.rejected]: (state, { payload }) => {},

    [deleteMailById.pending]: (state) => {},
    [deleteMailById.fulfilled]: (state, action) => {},
    [deleteMailById.rejected]: (state, { payload }) => {},

    [updateMailStatus.pending]: (state) => {},
    [updateMailStatus.fulfilled]: (state, action) => {},
    [updateMailStatus.rejected]: (state, { payload }) => {},
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = InboxSlice.actions;

export default InboxSlice.reducer;
