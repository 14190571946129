import React, { useEffect, useState } from "react";
import Avatar2 from "../../assets/images/avatar-2.svg";
import { ReactComponent as Plus } from "../../assets/icons/add.svg";
import { ReactComponent as Dropdown } from "../../assets/icons/dropdown.svg";
import { ReactComponent as InboxType } from "../../assets/icons/inbox-type.svg";
import { ReactComponent as Inbox } from "../../assets/icons/inbox.svg";
import { ReactComponent as Sent } from "../../assets/icons/sent.svg";
import { ReactComponent as Drafts } from "../../assets/icons/drafts.svg";
import { ReactComponent as Spam } from "../../assets/icons/spam.svg";
import { ReactComponent as Archive } from "../../assets/icons/archive.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as ManageAways } from "../../assets/icons/manage-away.svg";
import { ReactComponent as Contact } from "../../assets/icons/contacts.svg";

import "./Sidebar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedInbox } from "../../redux/Sidebar/Sidebar";

import { getProfile } from "../../utils/JWTDecoder";

const Sidebar = ({ away, setAway }) => {
  const [profileImage, setProfileImage] = useState(null);

  const url = useLocation();

  const { mailCounts } = useSelector((state) => state.inbox);
  const { selectedInbox } = useSelector((state) => state.sidebar);
  const { hash } = useLocation();

  console.log(url);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const profile = getProfile();
    setProfileImage(profile.photo);
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar__col-1">
        <div>
          <img className="profile" src={profileImage} alt="profile" />
        </div>
        <div>
          <img src={Avatar2} alt="alice" />
          <span></span>
        </div>
        <section></section>
        <Plus />
      </div>
      <div className="sidebar__col-2">
        <div
          className="sidebar__input-type"
          onClick={() => {
            dispatch(changeSelectedInbox());
          }}
        >
          <InboxType />
          {selectedInbox} Inbox
          <Dropdown />
        </div>
        <section className="sidebar__options">
          <div
            onClick={() => {
              navigate("/#inbox");
            }}
            className={`${
              (hash === "#inbox" || hash === "") && url.pathname === "/"
                ? "active"
                : ""
            }`}
          >
            <Inbox />
            Inbox <span>{mailCounts.messagesUnread}</span>
          </div>
          <div
            onClick={() => {
              navigate("/#sent");
            }}
            className={`${hash === "#sent" ? "active" : ""}`}
          >
            <Sent />
            Sent
          </div>
          <div
            onClick={() => {
              navigate("/#drafts");
            }}
            className={`${hash === "#drafts" ? "active" : ""}`}
          >
            <Drafts />
            Drafts
          </div>
          <div
            onClick={() => {
              navigate("/#spam");
            }}
            className={`${hash === "#spam" ? "active" : ""}`}
          >
            {" "}
            <Spam />
            Spam
          </div>
          <div
            onClick={() => {
              navigate("/#archive");
            }}
            className={`${hash === "#archive" ? "active" : ""}`}
          >
            <Archive />
            Archive
          </div>
          <div
            onClick={() => {
              navigate("/#trash");
            }}
            className={`${hash === "#trash" ? "active" : ""}`}
          >
            <Trash />
            Trash
          </div>
        </section>
        <section className="sidebar__away">
          <div className="sidebar__title">Away</div>
          <main>
            <div onClick={() => setAway(true)}>
              <Plus />
              Create Away
            </div>

            <div
              className={`${url.pathname === "/manage-aways" ? "active" : ""}`}
              onClick={() => navigate("/manage-aways")}
            >
              {" "}
              <ManageAways />
              Manage Aways
            </div>
          </main>
        </section>
        <section className="sidebar__contacts">
          <div className="sidebar__title">Contacts</div>
          <main>
            <div>
              <Contact />
              Manage Contacts
            </div>
          </main>
        </section>
      </div>
    </div>
  );
};

export default Sidebar;
