import jwtDecode from "jwt-decode";

export const getJWTToken = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  console.log(decodedToken);
  const currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    localStorage.removeItem("token");
    window.location.reload();
  } else {
    return token;
  }
};
