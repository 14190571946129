import React, { useEffect, useState } from "react";

import { ReactComponent as Step } from "../../assets/icons/step.svg";

import FrequentContacts from "../../components/FrequentContacts/FrequentContacts";

import "./ManageAways.scss";
import CreateAway from "../../components/CreateAway/CreateAway";
import { useDispatch, useSelector } from "react-redux";
import { getAwaysList } from "../../redux/Aways/Aways";
const ManageAways = ({ away, setAway }) => {
  const [skiTrip, setSkiTrip] = useState(false);
  const [quietTime, setQuietTime] = useState(false);

  return (
    <>
      <div className="manageaways">
        <div className="manageaways__container">
          <div className="manageaways__box">
            <h2>Step away from email</h2>
            <ul>
              <li>
                <Step />
                <span>
                  Hold your email and notifications for the amount of time you
                  need.
                </span>
              </li>
              <li>
                <Step />
                <span>
                  Senders are notified that you are away. Set rules to allow
                  contacts or keywords to reach you.
                </span>
              </li>
              <li>
                <Step />
                <span>
                  Resume your inbox if needed. Your email will be waiting for
                  you when you’re back.
                </span>
              </li>
            </ul>
            <button onClick={() => setAway(true)}>Set Your Away</button>
          </div>
        </div>
        <FrequentContacts  />{" "}
      </div>
    </>
  );
};

export default ManageAways;
