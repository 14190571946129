import React, { useEffect, useState } from "react";

import { ReactComponent as Dropdown } from "../../assets/icons/dropdown.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as Swimmer } from "../../assets/images/swimmer.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";

import EmailList from "../../components/EmailList/EmailList";

import "./Inbox.scss";
import { useDispatch, useSelector } from "react-redux";
import { getMailCounts, getUserInbox } from "../../redux/Inbox/Inbox";
import {
  getEmailTime,
  getFromEmail,
  getName,
  headerDate,
} from "../../utils/InboxFunctions";
import { MutatingDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import Feedbox from "../../components/Feedbox/Feedbox";

const Inbox = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [currentToken, setCurrentToken] = useState();

  const dispatch = useDispatch();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const { inbox } = useSelector((state) => state.inbox);
  const { mailCounts } = useSelector((state) => state.inbox);
  const { selectedInbox } = useSelector((state) => state.sidebar);

  const awaysList = useSelector((state) => state.aways.awaysList.data);

  useEffect(() => {
    setLoading(true);

    dispatch(getMailCounts());

    if (hash === "#inbox" || hash === "") {
      dispatch(getUserInbox({ val: "#inbox" })).then((data) => {
        const next = data?.payload?.nextPageToken;

        if (!tokens.includes(next) && next) {
          setTokens([...tokens, next]);
        }

        setLoading(false);
      });
    } else if (hash === "#sent") {
      dispatch(getUserInbox({ val: "#sent" })).then((data) => {
        setLoading(false);
      });
    } else if (hash === "#spam") {
      dispatch(getUserInbox({ val: "#spam" })).then((data) => {
        setLoading(false);
      });
    } else if (hash === "#trash") {
      dispatch(getUserInbox({ val: "#trash" })).then((data) => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const nextPage = () => {
    setLoading(true);
    dispatch(
      getUserInbox({
        val: "#inbox",
        next:
          tokens.length === 1
            ? tokens[0]
            : tokens[tokens.indexOf(currentToken) + 1],
      })
    ).then((data) => {
      setCurrentToken(data.payload.currentPageToken);
      const next = data?.payload?.nextPageToken;

      if (!tokens.includes(next) && next) {
        setTokens([...tokens, next]);
      }

      console.log(tokens[tokens.indexOf(currentToken) + 1]);

      setLoading(false);
    });
  };

  const previousPage = () => {
    setLoading(true);

    console.log(tokens.indexOf(currentToken) === 0);

    const index = tokens.indexOf(currentToken);

    console.log(index);

    dispatch(
      getUserInbox({
        val: "#inbox",
        next: index === 0 ? "" : tokens[index - 1],
      })
    ).then((data) => {
      setCurrentToken(data?.payload?.currentPageToken ?? "");

      setLoading(false);
    });
  };

  return (
    <div className="inbox__box">
      <div
        className={`inbox__box-menu ${
          awaysList?.find((e) => e.is_enabled === true) ? "away" : ""
        }`}
      >
        {!awaysList?.find((e) => e.is_enabled === true) ? (
          <div className="checkbox">
            <input type="checkbox" />
            <Dropdown />
          </div>
        ) : (
          <div className="away">
            Active aways:
            {awaysList.map((e) =>
              e.is_enabled ? (
                <main>
                  <span></span> {e.title}
                </main>
              ) : (
                ""
              )
            )}
          </div>
        )}
        <section>
          {!awaysList?.find((e) => e.is_enabled === true) &&
          (hash === "#inbox" || hash === "") ? (
            <main>
              <span>
                {!currentToken
                  ? `1 - ${
                      mailCounts.messagesTotal < 10
                        ? mailCounts.messagesTotal
                        : 10
                    }`
                  : `${tokens.indexOf(currentToken) + 1}1 - ${
                      (tokens.indexOf(currentToken) + 2) * 10 >=
                      mailCounts.messagesTotal
                        ? mailCounts.messagesTotal
                        : (tokens.indexOf(currentToken) + 2) * 10
                    }`}{" "}
                of {mailCounts.messagesTotal}
              </span>
              <Arrow
                style={{
                  opacity: currentToken ? 1 : 0.4,
                }}
                onClick={() => {
                  if (currentToken) {
                    previousPage();
                  }
                }}
              />
              <Arrow
                style={{
                  opacity:
                    (currentToken === "" && tokens.length > 0) ||
                    tokens.indexOf(currentToken) + 1 !== tokens.length
                      ? 1
                      : 0.4,
                }}
                onClick={() => {
                  if (
                    (currentToken === "" && tokens.length > 0) ||
                    tokens.indexOf(currentToken) + 1 !== tokens.length
                  ) {
                    nextPage();
                  }
                }}
              />
            </main>
          ) : (
            <main>
              <span style={{ color: "white" }}>
                {mailCounts.messagesTotal} Total Messages
              </span>
            </main>
          )}
          <div>{mailCounts?.messagesUnread ?? 0} Unread</div>
        </section>
      </div>
      <div className="inbox__list-container">
        {awaysList?.find((e) => e.is_enabled === true) && !loading && (
          <div className="away__box">
            <Swimmer />
            <h2>Inbox paused</h2>
            <p>
              You currently have an active Away. Want to resume receiving
              messages?
            </p>
            <div onClick={() => navigate("/manage-aways")}>
              Manage Active Aways
            </div>
          </div>
        )}

        <main>
          {inbox?.length > 0 &&
            !loading &&
            inbox.map((inb, index) => {
              if (
                headerDate(inb.sent) !==
                  headerDate(inbox[index === 0 ? index : index - 1].sent) ||
                index === 0
              ) {
                return (
                  <>
                    <div className="inbox__list-day">
                      <Clock />
                      {headerDate(inb.sent)}
                    </div>
                    {selectedInbox === "Classic" ? (
                      <EmailList
                        name={hash === "#sent" ? "To: " : getName(inb.from)}
                        body={inb.snippet}
                        subject={inb.subject}
                        read={!inb.is_read}
                        email={
                          hash === "#sent" ? inb.to : getFromEmail(inb.from)
                        }
                        time={getEmailTime(inb.sent)}
                        id={inb.message_id}
                      />
                    ) : (
                      <Feedbox
                        name={hash === "#sent" ? "To: " : getName(inb.from)}
                        body={inb.snippet}
                        subject={inb.subject}
                        read={!inb.is_read}
                        email={
                          hash === "#sent" ? inb.to : getFromEmail(inb.from)
                        }
                        time={getEmailTime(inb.sent)}
                        id={inb.message_id}
                      />
                    )}
                  </>
                );
              }
              return selectedInbox === "Classic" ? (
                <EmailList
                  name={hash === "#sent" ? "To: " : getName(inb.from)}
                  body={inb.snippet}
                  subject={inb.subject}
                  read={!inb.is_read}
                  email={hash === "#sent" ? inb.to : getFromEmail(inb.from)}
                  time={getEmailTime(inb.sent)}
                  id={inb.message_id}
                />
              ) : (
                <Feedbox
                  name={hash === "#sent" ? "To: " : getName(inb.from)}
                  body={inb.snippet}
                  subject={inb.subject}
                  read={!inb.is_read}
                  email={hash === "#sent" ? inb.to : getFromEmail(inb.from)}
                  time={getEmailTime(inb.sent)}
                  id={inb.message_id}
                />
              );
            })}
          {Boolean(inbox) && loading && (
            <MutatingDots
              height="100"
              width="100"
              color="#49b2b9"
              secondaryColor="#49b2b9"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default Inbox;
